import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CertificacionesEnsayosLaboratorio = () => {
  return (
    <Layout location="/certificaciones">
      <SEO title="Certificaciones" />
      <h1>CERTIFICACION PERFIL ACERO ABIERTO</h1>
    </Layout>
  )
}

export default CertificacionesEnsayosLaboratorio
